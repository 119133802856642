<template>
  <div class="UploadImg">
    <el-upload
      v-show="!resultImg && !resultFile && !isProgress"
      class="upload-el"
      :accept="accept"
      ref="fileUpload"
      name="pic"
      :action="action"
      :data="uploadData"
      :on-change="selectChange"
      :show-file-list="false"
      :auto-upload="false"
      :http-request="httpRequest"
    >
      <div class="upload-box">
        <div v-if="accept === 'image/*'" class="upload-image">
          <img src="../../assets/addphoto.png" alt="" />
          {{ uploadName }}
        </div>
        <div v-if="accept === 'audio/*'" class="upload-audio">选择文件</div>
        <div slot="tip" class="tip" v-if="tip">
          {{ tip }}
        </div>
      </div>
    </el-upload>
    <figure v-show="resultImg && accept === 'image/*'" class="result-img">
      <img :src="resultImg" />
      <el-button @click="updateCropper" class="blue-btn">重新上传</el-button>
    </figure>
    <figure
      v-show="resultFile && accept === 'audio/*' && !isProgress"
      class="result-file"
    >
      <audio
        :src="resultFile"
        controls
        id="audioTag"
        @timeupdate="updateProgress"
        @play="audioPlay"
        @pause="audioPause"
        ref="audioPlayer"
      />
      <el-button @click="updateCropper" class="blue-btn">重新上传</el-button>
    </figure>
    <div v-show="isProgress" class="progress-box">
      <div class="loader"></div>
      {{ accept === "audio/*" ? "音频上传中" : "文件上传中"
      }}{{ progressPercent }}%
    </div>
    <cropper
      v-if="showCropper"
      :dialog-visible="showCropper"
      :cropper-img="cropperImg"
      @update-cropper="updateCropper"
      @colse-dialog="closeDialog"
      @upload-img="uploadImg"
      :fixedNumber="fixedNumber"
    />
  </div>
</template>

<script>
import Cropper from "../Cropper";
import axios from "axios";
import { uploadOss } from "@/api/login";
export default {
  name: "UploadImg",
  components: {
    Cropper,
  },
  props: {
    // 文件类型
    accept: {
      type: String,
      default: "",
    },
    // 提示语
    tip: {
      type: String,
      default: "",
    },
    // 上传文字
    uploadName: {
      type: String,
      default: "上传图片",
    },
    // 是否裁剪
    fixedFlag: {
      type: Boolean,
      default: false,
    },
    // 裁剪尺寸
    fixedNumber: {
      type: Array,
      default: () => [9, 16],
    },
    fixedName: {
      type: String,
      default: "9:16",
    },
  },
  data() {
    return {
      uploadData: {
        // 上传需要的额外参数
        siteId: 1,
        source: 1,
      },
      action: "#", // 上传地址，必填
      cropperImg: "", // 需要裁剪的图片
      showCropper: false, // 是否显示裁剪框
      uploadFile: "", // 裁剪后的文件
      resultImg: "", // 上传成功，后台返回的路径
      resultFile: "",
      progressPercent: 0,
      isProgress: false,
    };
  },
  methods: {
    init(key, value) {
      this[key] = value;
    },
    // submit 之后会触发此方法
    httpRequest() {
      // console.log("111 :>> ", this.uploadFile instanceof File);
      const that = this;
      if (this.uploadFile instanceof File) {
        this.isProgress = true;
        let formData = new FormData();
        formData.append("file", this.uploadFile);
        formData.append("fileDir", "audio");
        axios({
          method: "POST",
          url: `${process.env.VUE_APP_BASE_API}/boxing/common/uploadFile`,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            that.progressPercent = ((loaded / total) * 100).toFixed(2);
            console.log("this.progressPercent :>> ", that.progressPercent);
          },
        })
          .then((res) => {
            console.log("res :>> ", res);
            if (res.data.code === 200) {
              that.$message.success("文件上传成功");
              that.resultFile = res.data.result;
              that.isProgress = false;
              that.$emit("uploadInfo", that.resultFile);
              return;
            }
            // failure("上传失败");
            that.isProgress = false;
            that.$message.error("文件上传失败，请稍后重试");
          })
          .catch(() => {
            // failure("上传出错");
            that.isProgress = false;
            this.$message.error("文件上传失败，请稍后重试");
          });
      } else {
        let formData = new FormData();
        let file = new File(
          [this.uploadFile],
          this.$refs.fileUpload.uploadFiles[0].name,
          { type: this.uploadFile.type, lastModified: Date.now() }
        );
        file.uid = Date.now();
        formData.append("file", file);
        formData.append("fileDir", "rhythm");
        uploadOss(formData)
          .then((res) => {
            // console.log(res);
            if (res.code === 200) {
              //   const file = res.data
              this.$message.success("图片上传成功");
              this.resultImg = res.result;
              this.$emit("uploadInfo", this.resultImg);
              this.closeDialog();
              return;
            }
            // failure("上传失败");
            this.$message.error("图片上传失败，请稍后重试");
            this.closeDialog();
          })
          .catch(() => {
            // failure("上传出错");
            this.$message.error("图片上传失败，请稍后重试");
            this.closeDialog();
          });
      }
    },
    // 选择文件
    selectChange(file) {
      const { raw } = file;
      if (this.fixedFlag) {
        this.openCropper(raw);
      } else {
        this.uploadFile = raw;
        this.$refs.fileUpload.submit();
        // console.log("raw :>> ", raw);
      }
    },
    /**
     * @param {file} 上传的文件
     */
    openCropper(file) {
      var files = file;
      let isLt5M = files.size > 5 << 20;
      if (isLt5M) {
        this.$message.error("请上传5M内的图片");
        return false;
      }
      var reader = new FileReader();
      reader.onload = (e) => {
        let data;
        if (typeof e.target.result === "object") {
          // 把Array Buffer转化为blob 如果是base64不需要
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        this.cropperImg = data;
      };
      // 转化为base64
      // reader.readAsDataURL(file)
      // 转化为blob
      reader.readAsArrayBuffer(files);
      this.showCropper = true;
    },
    // 上传图片
    uploadImg(file) {
      this.uploadFile = file;
      this.$refs.fileUpload.submit();
    },
    // 更新图片
    updateCropper() {
      this.$refs.fileUpload.$children[0].$el.click();
    },
    // 关闭窗口
    closeDialog() {
      this.showCropper = false;
    },
    updateProgress(event) {
      // console.log("event :>> ", event.target.currentTime);
      this.$emit("updateProgress", event.target.currentTime);
    },
    audioPlay() {
      const audioPlayer = this.$refs.audioPlayer;
      audioPlayer.play().then(() => {
        audioPlayer.blur(); // 使audio元素失焦
      });
      this.$emit("updatePlay", true);
    },
    audioPause() {
      this.$emit("updatePlay", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.UploadImg {
  .result-img {
    display: flex;
    width: 100px;
    align-items: center;
    img {
      width: 100%;
      display: block;
      margin-right: 20px;
      border-radius: 10px;
    }
    .el-button {
      height: 40px;
      line-height: 40px;
      padding: 0 20px;
    }
  }
  .result-file {
    display: flex;
    align-items: center;
  }
  .upload-box {
    display: flex;
    align-items: center;
    .upload-image {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 23px 20px 19px;
      background: #f5f6f6;
      border-radius: 2px;
      font-weight: 400;
      font-size: 15px;
      color: #bbbbbb;
      line-height: 21px;
      margin-right: 10px;
      img {
        width: 32px;
        height: 32px;
        margin-bottom: 5px;
      }
    }
    .upload-audio {
      padding: 6px 13px 5px 14px;
      background: #f5f6f6;
      border-radius: 2px;
      font-weight: 400;
      font-size: 15px;
      color: #bbbbbb;
      line-height: 21px;
      margin-right: 10px;
    }
    .tip {
      font-size: 16px;
      color: #696969;
      line-height: 21px;
    }
  }
  .progress-box {
    /* HTML: <div class="loader"></div> */
    width: 200px;
    background: rgba(156, 156, 156, 0.3);
    padding: 20px 0;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // font-size: 20px;

    color: #000;
    .loader {
      margin-bottom: 10px;
      width: 50px;
      --b: 8px;
      aspect-ratio: 1;
      border-radius: 50%;
      padding: 1px;
      background: conic-gradient(#0000 10%, #f03355) content-box;
      -webkit-mask: repeating-conic-gradient(
          #0000 0deg,
          #000 1deg 20deg,
          #0000 21deg 36deg
        ),
        radial-gradient(
          farthest-side,
          #0000 calc(100% - var(--b) - 1px),
          #000 calc(100% - var(--b))
        );
      -webkit-mask-composite: destination-in;
      mask-composite: intersect;
      animation: l4 1s infinite steps(10);
    }
    @keyframes l4 {
      to {
        transform: rotate(1turn);
      }
    }
  }
}
</style>
